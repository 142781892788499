body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.mainactivity{
  width: 100%;
  margin-top: 70px;
  padding-left: 5%;
  padding-right: 5%;
}
.navbar{
  position: fixed !important;
  width: 100%;
  background-color: #fff;
  top: 0;
  z-index: 100;
}
.btnsync{
  bottom: 20px;
  position: fixed;
  width: 90%;
  text-align: center;
}
.navbar-brand{
  color: #fff;
  font-weight: bold;
  display: flex!important;
  align-items: center;
}
.navbar-toggler{
  background-color: #fff!important;
}

.nav-link{
  color: #fff;

}
.active{
  font-weight: bold;
}
.jadwal{
  display: flex;
  align-items: center;
  color: gray;
}
.r1r{
  margin-right: 0.5rem!important
}
.tombol{
  display: flex
}
.f1{
  flex: 1;
  padding: 0!important;
  margin-left: 2px;
  margin-right: 2px;
}
.t1{
  margin-top: 1rem
}
h5{
  margin-bottom: 0px!important;
}
.foto{
  width: 100%;
}

.login-container{
  padding-left: 5%;
  padding-right: 5%;
  margin: 0;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.login-form{
  border: 1px solid;
  padding-left: 5%;
  padding-right: 5%;
  /* padding-top: 5%; */
  padding-bottom: 5%;
}
.logo-container{
  /* background-color: yellowgreen; */
  width: 100%;
  border-bottom-right-radius: 20%;
  text-align: center;
}