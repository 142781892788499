.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}
.t1{
  margin-top: 1em;
}
.b1{
  margin-bottom: 1em;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
.online-indicator{
  height: 20px;
  width: 20px;
  margin-right: 5%;
  border-radius: 4px;
}
.online{
  background-color: #0eff00;
}
.offline{
  background-color: red;
}